@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    position: relative;
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}


a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(1100);
}


#footer{
    // position: fixed;
    z-index: 6;
    bottom: 0;
    left: 0;
    width: 100%;
    
    &::before{
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        content:'';
        background: transparent linear-gradient(0deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
        opacity: 0.35;
    }

    .container{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media screen and (max-width: 900px){
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;
            svg{
                margin: 0 auto;
            }
        }
    }

    .social{
        margin: rem(8) 0;
        a{
            display: inline-block;
            &+a{
                margin-left: 1em;
            }
        }
    }

    .copyright{
        width: rem(500);
        max-width: 100%;
        font-family: sans-serif;
        font-size: rem(8);
        a{
            display: inline-block;
            text-decoration: none;
        }
        svg{
            display: block;
            width: rem(60);
            height: auto;
            margin-bottom: rem(6);
        }
    }
}

#main{
    #signup{
        background-size: 100% auto;
        background-position: bottom center;
        background-repeat: no-repeat;
        text-align: center;
        h1{
            margin: rem(30) 0;
            text-align: center;
            img{
                max-width: rem(116);
            }
        }

        .container{
            max-width: rem(1400);
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width:900px){
                flex-direction: column;
                position: relative;
                padding-top: rem(120);
                h1{
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%); 
                }
            }
            .col{
                flex: 1;
                max-width: 100%;
                &:last-child{
                    padding: 0 rem(10);
                }
                
                @media screen and (max-width:550px){
                    &:first-child{
                        img{
                            height: 130vw;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
            }
        }

        .arrow{
            width: rem(40);
            margin-top: rem(25);
            transform: rotate(180deg);
        }
        
        // @media screen and (max-width: 900px){
        //     position: relative;
        //     min-height: 0;
        //     padding: rem(120) 0 rem(40);
        //     .container{
        //         padding-bottom: rem(30);
        //     }

            
        //     background-size: 100% auto, 140% auto;
        //     background-image: url('../img/Fire_Long.png'),url('../img/edited+pressphoto.png');
        //     background-position: 50% 80vw , top center;
        //     background-repeat: no-repeat;
        // }

        h2{
            font-weight: normal;
            margin: 0;
            font-size: rem(48);
            letter-spacing: .01em;
            line-height: 1.1;
            span{
                display: inline-block;
            }
        }
        p{
            font-size: rem(16);
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: .01em;
            font-family: sans-serif;
            width: rem(390);
            max-width: 100%;
            margin: 1em auto;
        }

        form{
            img{
                display: block;
                margin: 0 auto;
                width: rem(700);
                max-width: 100%;
            };

            input{
                border: none;
                background: none;
                text-align: center;
                font-size: rem(28);
                color: $white;
                &::placeholder{
                    color: $white;
                    opacity: 1;
                }
            }
            button{
                margin-top: rem(30);
                border: none;
                background-color: transparent;
                background-image: url('../img/btn.svg');
                color: $white;
                background-size: 100% 100%;
                font-family: $secondary-font;
                font-size: rem(32);
                padding: rem(15) rem(40);
                @include hover{
                    opacity: .7;
                }
            }
            @media screen and (max-width: 900px){
                input{
                    font-size: rem(22);
                }
                p{
                    font-size: rem(14);
                    width: rem(350);
                }
                button{
                    font-size: rem(27);
                    padding: rem(10) rem(20);
                }
            }
        }
    }

    #videos{
        text-align: center;
        @media screen and (max-width: 900px){
            margin-top: rem(70);
        }
        h2{
            font-size: rem(70);
            margin: 0 auto .5em;
            font-family: $secondary-font;
            font-weight: normal;
            @media screen and (max-width: 900px){
            }
        }
        p{
            font-size: rem(24)
        }
        .item{
            padding: 0 rem(15);
        }

        .slick-prev,.slick-next{
            background: none;
            border: none;
            img{
                width: rem(20);
                transition: .3s all;
            }
            @include hover{
                img{
                    opacity: .7;
                }
            }
        }
        .slick-next img{
            transform: rotate(90deg);
        }
        .slick-next{
            margin-left: rem(50);
        }
        .slick-prev img{
            transform: rotate(270deg);
        }

        .music-wrap{
            padding: 0 0 rem(50);
            margin: 0 auto;
            width: 100%;
            max-width: rem(600);
            img{
                display: block;
            }
            p{
                font-size: rem(32);
                span{
                    display: block;
                    font-size: rem(28)
                }
            }
        }

        .btn{
            margin-top: rem(30);
            text-decoration: none;
            display: inline-block;
            border: none;
            background-color: transparent;
            background-image: url('../img/btn.svg');
            color: $white;
            background-size: 100% 100%;
            font-family: $secondary-font;
            font-size: rem(32);
            padding: rem(15) rem(40);
            @include hover{
                opacity: .7;
            }
        }
    }
    #tour{
        padding: rem(50) 0;
        
        h2{
            text-align: center;
            font-size: rem(70);
            margin: .5em auto;
            font-family: $secondary-font;
            font-weight: normal;
        }
        #events{
            .event{
                padding: 0 rem(30) rem(30);
                font-size: rem(30);
                display: flex;
                align-items: center;
                position: relative;
                margin-bottom: rem(30);
                @media screen and (max-width: 900px){
                    text-align: center;
                    flex-direction: column;
                    gap: rem(10);
                }
                &::after{
                    content:'';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: rem(30);
                    background-image: url('../img/Divider.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    @media screen and (max-width: 900px){
                        background-image: url('../img/Divider_Mobile.png');
                    }
                }
                .event-date{ 
                    flex: .5;
                }
                .event-location{
                    flex: 1;
                    padding: 0 rem(10);
                }
                .event-venue{
                    flex: 1;
                    padding: 0 rem(10);
                }
                
                .btn{
                    border: none;
                    text-decoration: none;;
                    background-color: transparent;
                    background-image: url('../img/btn.svg');
                    color: $white;
                    background-size: 100% 100%;
                    font-family: $secondary-font;
                    font-size: rem(35);
                    padding: rem(5) rem(20);
                    @include hover{
                        opacity: .7;
                    }
                }
            }
            .noevent{
                font-size: rem(20);
                text-align: center;
            }
        }
    }
}
