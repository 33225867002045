@font-face {
    font-family: 'Hideout';
    src: url('../font/Hideout-GhostBlack.woff2') format('woff2'),
        url('../font/Hideout-GhostBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mothman';
    src: url('../font/Mothman.woff2') format('woff2'),
        url('../font/Mothman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'EngrvrsOldEng BT';
    src: url('../font/EngraversOldEnglishBT-Regular.woff2') format('woff2'),
        url('../font/EngraversOldEnglishBT-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


