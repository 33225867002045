//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #FC0000;
$gray: #656565;

//Fonts
// $primary-font: 'Hideout', sans-serif;
// $secondary-font: 'Mothman', sans-serif;
$primary-font: 'EngrvrsOldEng BT', serif; 
$secondary-font: $primary-font;
